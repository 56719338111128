
















































import { Component, Vue } from 'vue-property-decorator'
import AppNavDrawer from '@/components/App/AppNavDrawer.vue'
import axiosInstance from '@/plugins/axios'

@Component({
  components: {
    AppNavDrawer
  }
})
export default class UsersView extends Vue {
  isLoading = false

  headers = [
    { text: 'Username', value: 'username' },
    { text: 'ຊື່', value: 'name' },
    { text: 'ເບີໂທລະສັບ', value: 'phone' },
    { text: 'ສິດ', value: 'roles' },
    { text: '', value: 'action' }
  ]

  users = []

  created () {
    this.fetchUsers()
  }

  async fetchUsers (): Promise<void> {
    try {
      this.isLoading = true
      const res = await axiosInstance.get('/user/users')
      const data: any = res.data
      this.users = data.map(item => {
        return {
          username: item.username,
          name: item.name,
          phone: item.phone,
          roles: item.roles,
        }
      })
      this.isLoading = false
    } catch (error) {
      console.error(error)
      this.isLoading = false
    }
  }
}
